import React, { lazy } from 'react';
import Home from '../pages/home';
import { Navigate } from 'react-router-dom';

// 懒加载只能针对挂载在Home组件下的组件，因为Suspense组件放在Home中
const Index = lazy(() => import('../pages/index'));
const TeamList = lazy(() => import('../pages/team/list'));
const TeamDetail = lazy(() => import('../pages/team/detail'));
const ActivityList = lazy(() => import('../pages/activity/list'));
const ActivityDetail = lazy(() => import('../pages/activity/detail'));
const AllianceDetail = lazy(() => import('../pages/allianceDetail'));
const ConsultationApply = lazy(() => import('../pages/apply/consultationApply'));
const CooperationApply = lazy(() => import('../pages/apply/cooperationApply'));

export type routerConfigItem = {
  path: string;
  code?: string;
  element?: React.ReactNode;
  hideInMenu?: boolean;
  children?: routerConfigItem[];
  meta?: any;
};

export const routerConfig: routerConfigItem[] = [
  {
    path: '/:unionId',
    element: <Home />,
    children: [
      // {
      //   path: '/',
      //   element: <Navigate to="/index" replace />,
      // },
      {
        path: '/:unionId/index',
        element: <Index />,
      },
      {
        path: '/:unionId/allianceDetail',
        element: <AllianceDetail />,
      },
      {
        path: '/:unionId/team',
        element: <TeamList />,
      },
      {
        path: '/:unionId/team/detail',
        element: <TeamDetail />,
      },
      {
        path: '/:unionId/activity',
        element: <ActivityList />,
      },
      {
        path: '/:unionId/activity/detail',
        element: <ActivityDetail />,
      },
      {
        path: '/:unionId/apply/consultationApply',
        element: <ConsultationApply />,
      },
      {
        path: '/:unionId/apply/cooperationApply',
        element: <CooperationApply />,
      },
    ],
  },
];

function mapRouterConfig(config: routerConfigItem[], fn: any, parentPath = '') {
  config.forEach((item) => {
    fn && fn(item, parentPath);
    if (item.children) {
      const en = item.path.startsWith('/') || parentPath.endsWith('/') ? '' : '/';
      mapRouterConfig(item.children, fn, parentPath + en + item.path);
    }
  });
}
const hideInMenuPages: string[] = [];
mapRouterConfig(routerConfig, (router: routerConfigItem, parentPath: string) => {
  if (router.hideInMenu) {
    const en = router.path.startsWith('/') || parentPath.endsWith('/') ? '' : '/';
    hideInMenuPages.push(parentPath + en + router.path);
  }
});
export { hideInMenuPages };
export default routerConfig;
