import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ConfigProvider, DotLoading } from 'antd-mobile';
import zhCN from 'antd-mobile/es/locales/zh-CN';
import moment from 'moment';
import App from './App';
import '@/style/iconfont/iconfont.css';
import 'moment/locale/zh-cn';
import { AliveScope } from 'react-activation';

moment.locale('zh-cn');

const loading = (
  <div style={{ display: 'flex', height: '100vh' }}>
    <DotLoading style={{ margin: 'auto' }} />
  </div>
);
ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <RecoilRoot>
      <Suspense fallback={loading}>
        <HashRouter>
          <AliveScope>
            <App />
          </AliveScope>
        </HashRouter>
      </Suspense>
    </RecoilRoot>
  </ConfigProvider>,
  document.getElementById('root'),
);
