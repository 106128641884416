const { NODE_ENV } = process.env;
const { origin, pathname } = window.location;
export const baseURL = NODE_ENV === 'development' ? 'https://admin-imdt-dev.zmnyun.cn/' : origin + pathname;
export const scope = 'sdc-hccm';
export const appName = 'sdc-hccm';
export const clientPrefix = '/api';
export const isDev = NODE_ENV === 'development';
export const socketURL =
  NODE_ENV === 'development'
    ? 'ws://dev.sdc.sinohealth.cn:30200/webSocket'
    : `ws://${window.location.hostname}:30200/webSocket`;
export const getTokenParams = {
  clientId: 'healthplus-oms',
  clientSecret: 'healthplus-oms',
  scope: 'zmn-rx-oms-server',
};
export const loginRememberKey = 'zk-hccm-w'; // 记住密码时保存数据的key
export default {
  baseURL,
  getTokenParams,
};
export const sendCodeTimeKey = 'zk-c-s-c';
