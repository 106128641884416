import React, { Suspense, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import styles from './index.less';

const logo = require('@/assets/images/common/logo.png');

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { unionId } = params;
  useEffect(() => {
    if (unionId && (window.location.hash.endsWith(unionId) || window.location.hash.endsWith(`${unionId}/`))) {
      navigate('index');
    }
  }, []);
  return (
    <div className={styles.home}>
      <Suspense fallback="loading...">
        <Outlet />
      </Suspense>
      <img className={styles.logo} src={logo} />
    </div>
  );
};

export default Home;
