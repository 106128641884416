import { request } from '@/common/request';
import { handleDicToObj } from '@/utils';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState, atom, selector } from 'recoil';

export const dictState = atom({
  key: 'dictState',
  default: null,
});

export const dictStateKeyValue = atom({
  key: 'dictStateKeyValue',
  default: null,
});

export const getSystemDict = () => {
  // @ts-ignore
  return request.get('/sys/dict/list', { isReturnAllData: true });
};

/**
 * 字典数据
 */

export const useDict = () => {
  const setDictStateList = useSetRecoilState(dictState);
  const dictStateList = useRecoilValue(dictState);
  const [dictObj, setDictObj] = useState<any>(null);
  async function httpGetSystemDictReq() {
    if (dictStateList) {
      setDictObj(dictStateList);
    } else {
      const res: any = await getSystemDict();
      setDictStateList(res.data);
      setDictObj(res.data);
    }
  }
  useEffect(() => {
    httpGetSystemDictReq();
  }, []);

  return dictObj;
};

export const useDictKeyValue = () => {
  const setDictStateList = useSetRecoilState(dictStateKeyValue);
  const dictStateList = useRecoilValue(dictStateKeyValue);
  const [dictObj, setDictObj] = useState<any>(null);
  async function httpGetSystemDictReq() {
    if (dictStateList) {
      setDictObj(dictStateList);
    } else {
      const res: any = await getSystemDict();
      if (res) {
        const newDictObj: any = handleDicToObj(res.data);
        setDictStateList(newDictObj);
        setDictObj(newDictObj);
      }
    }
  }
  useEffect(() => {
    httpGetSystemDictReq();
  }, []);

  return dictObj;
};

export const fieldNames = {
  label: 'name',
  value: 'code',
};

export default {};
